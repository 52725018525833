var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mile-history",
      class: { "mile-history--none": !_vm.hasContents },
    },
    [
      _vm.hasContents
        ? [
            _vm._l(_vm.contents, function (contentItems, i) {
              return _c("LogCards", {
                key: i,
                attrs: { contents: contentItems },
              })
            }),
            _vm.nextPageUrl
              ? _c(
                  "div",
                  { staticClass: "c-section" },
                  [
                    _c(
                      "Button",
                      {
                        attrs: { "style-type": "quaternary", fluid: "" },
                        on: { click: _vm.loadMore },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.WORDS.MILE_HISTORY) +
                            _vm._s(_vm.WORDS.MORE) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : !_vm.$_loading_state
        ? [
            _c("Empty", {
              attrs: {
                "icon-name": "mileOn",
                text: "獲得したマイルはありません",
              },
            }),
            _c(
              "div",
              { staticClass: "mile-history__empty-actions" },
              [
                _c(
                  "Button",
                  {
                    attrs: {
                      "style-type": "primary",
                      fluid: "",
                      href: _vm.homeUrl,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.WORDS.BACK_HOME_MILE) + " ")]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }