<template>
  <div class="mile-history" :class="{ 'mile-history--none': !hasContents }">
    <template v-if="hasContents">
      <LogCards
        v-for="(contentItems, i) in contents"
        :key="i"
        :contents="contentItems" />

      <div v-if="nextPageUrl" class="c-section">
        <Button style-type="quaternary" fluid @click="loadMore">
          {{ WORDS.MILE_HISTORY }}{{ WORDS.MORE }}
        </Button>
      </div>
    </template>

    <template v-else-if="!$_loading_state">
      <Empty icon-name="mileOn" text="獲得したマイルはありません" />
      <div class="mile-history__empty-actions">
        <Button style-type="primary" fluid :href="homeUrl">
          {{ WORDS.BACK_HOME_MILE }}
        </Button>
      </div>
    </template>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import moment from 'moment';
import { groupBy, orderBy } from 'lodash';
import { MileageMissionType, SystemDialogMessage } from '@/constants/enums';
import { defineComponent, ref, computed } from '@vue/composition-api';

const ACTIONS = [
  { type: MileageMissionType.READ_CONTENT, icon: 'bookOn' },
  { type: MileageMissionType.QR_PRODUCT_ACCESS, icon: 'qr' },
  { type: MileageMissionType.JAN_PRODUCT_ACCESS, icon: 'barcode' },
  { type: MileageMissionType.MAKE_IMPRESSION, icon: 'mycoffeeOff' },
  { type: MileageMissionType.EVENT_CONTENT, icon: 'event' },
  { type: MileageMissionType.CAMPAIGN, icon: 'flag' },
  { type: MileageMissionType.LOGIN_BONUS, icon: 'mileOn' },
  { type: MileageMissionType.VIDEO, icon: 'video' },
  { type: MileageMissionType.GRANTED_BY_ADMIN, icon: 'office' }
];

export default defineComponent({
  components: {
    LogCards: () => import('@/components/mypage/LogCards.vue')
  },

  setup: (props, context) => {
    const logs = ref([]);
    const nextPageUrl = ref(null);
    const hasContents = computed(() => contents.value.length > 0);

    /**
     * UI展開用に整形したマイル履歴一覧情報
     */
    const contents = computed(() => {
      // 履歴データを日付でグループ化
      const group = groupBy(logs.value, (log) =>
        moment(log.created_at).format('YYYYMMDD')
      );

      // 登録日時 降順にソート
      return orderBy(Object.entries(group), ['0'], 'desc').map((v) => ({
        date: context.root.$utilities.localizeDateWeek(v[0]),
        items: orderBy(
          v[1]
            .map((log) => ({
              ...log,
              ...ACTIONS.find((action) => action.type === log.message_type),
              title: MileageMissionType.getTypeName(log.message_type),
              time: context.root.$utilities.localizeTime(log.created_at)
            }))
            .filter((v) => v),
          ['created_at'],
          ['desc']
        )
      }));
    });

    const homeUrl = computed(() =>
      context.root.$customUrlScheme.page('root/home/1')
    );

    const fetchList = async () => {
      try {
        context.root.$_loading_start();

        const { data } = await context.root
          .$repositories('mileage')
          .getHistories(
            context.root.$utilities.getUrlSearch(nextPageUrl.value)
          );
        logs.value.push(...data.data);
        nextPageUrl.value = data.next_page_url;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    const loadMore = async () => {
      if (context.root.$_loading_state) return;
      await fetchList();
    };

    fetchList();

    return {
      WORDS,
      nextPageUrl,

      hasContents,
      contents,
      homeUrl,
      loadMore
    };
  }
});
</script>

<style lang="scss" scoped>
.mile-history {
  padding-bottom: 2.4rem;
}

.mile-history--none {
  .c-empty {
    padding-bottom: 3.2rem;
  }

  .mile-history__empty-actions {
    padding: 0 3.2rem;
  }
}
</style>
